import './css/index.scss';
import Carousel from './js/carousel';
import MobileMenu from './js/mobile-menu';

document.addEventListener('DOMContentLoaded', () => {
  sliders();
  mobileMenu();
});


const sliders = () => {
  const carousel = document.querySelector('#what');
  if (carousel !== null) {
    new Carousel(carousel);
  }
};

const mobileMenu = () => {
  const menu = document.querySelector('.navbar-mobile');
  if (menu === null) {
    return;
  }
  new MobileMenu(menu);
};