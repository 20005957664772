class Carousel {
    constructor($rootNode) {
        this.$carousel = $rootNode;
        if (this.$carousel === null) {
            console.error('Carousel root node does not exist in the DOM');
        }
        this.$slides = this.$carousel.querySelectorAll('.carousel-active');
        this.$controls = this.$carousel.querySelectorAll('.carousel-control');
        this.itemsLength = this.$slides.length;
        this.controlsLength = this.$controls.length;
        this.controlsLength = 0;
        this.interval = 4000;
        this.carouselTimer = setInterval(this.carouselPlay.bind(this), this.interval);
        this.carouselControls();
    }

    carouselControls() {
        for (var i = this.controlsLength; i--;) {
            this.$controls[i].addEventListener('click', this.carouselReset.bind(this), false);
        }
    }

    carouselPlay() {
        for (let i = this.itemsLength; i--;) {
            if (this.$slides[i].checked) {
                if (i !== this.itemsLength - 1) {
                    this.$slides[i + 1].checked = true;
                } else if (i === this.itemsLength - 1) {
                    setTimeout(() => this.$slides[0].checked = true, 0);
                }
            }
        }
    }

    carouselReset() {
        clearInterval(this.carouselTimer);
        this.carouselTimer = setInterval(this.carouselPlay.bind(this), this.interval);
    }
}

export default Carousel;
