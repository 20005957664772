const 
    MENU_HAMBURGUER = 'navbar-mobile__menu',
    MENU_LINKS = 'navbar-mobile__links',
    MENU_LINK = 'navbar-mobile__link',
    MENU_LINKS_OPENED = 'navbar-mobile__links--opened',
    ACTION_ICON = 'icon',
    ACTION_ICON_HIDDEN = 'icon--hidden';


class MobileMenu {
    constructor($rootNode) {
        this.$menu = $rootNode;
        this.$hamburguerMenu = $rootNode.querySelector(`.${MENU_HAMBURGUER}`);
        this.$menuLinks = $rootNode.querySelector(`.${MENU_LINKS}`);
        this.$actionIcons = $rootNode.querySelectorAll(`.${ACTION_ICON}`);
        this.validateMenuDOM();
        this.bindEvents();
    }

    validateMenuDOM() {
        if (this.$hamburguerMenu === null) {
            throw new Error('Hamburguer menu not found in DOM');
        }
        if (this.$menuLinks === null) {
            throw new Error('Menu links not found in DOM');
        }
        if (this.$actionIcons.length === 0) {
            throw new Error('Menu icons not found in DOM');
        }
    }

    bindEvents() {
        this.$hamburguerMenu.addEventListener('click', this.toggleMenuVisibility.bind(this));      
        this.$menuLinks
            .querySelectorAll(`.${MENU_LINK}`)
            .forEach(item => item.addEventListener('click', this.closeMenu.bind(this)));
    }

    toggleMenuVisibility() {
        if (this.isMenuOpen()) {
            this.$menuLinks.classList.remove(MENU_LINKS_OPENED);
        } else {
            this.$menuLinks.classList.add(MENU_LINKS_OPENED);
        }
        this.$actionIcons.forEach(icon => this.toggleActionIcon(icon));
    }

    closeMenu() {
        if (this.isMenuOpen()) {
            this.$menuLinks.classList.remove(MENU_LINKS_OPENED);
        }
    }

    isMenuOpen() {
        return this.$menuLinks.classList.contains(MENU_LINKS_OPENED);
    }

    toggleActionIcon(icon) {
        if (icon.classList.contains(ACTION_ICON_HIDDEN)) {
            icon.classList.remove(ACTION_ICON_HIDDEN);
        } else {
            icon.classList.add(ACTION_ICON_HIDDEN);
        }
    }
}

export default MobileMenu;